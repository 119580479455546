@font-face {
    font-family: 'Papirus';
    src: url('./1papyrus.ttf') format('truetype');
}

.bWhite {
    /* background-color: white; */
    background-image: linear-gradient(to right, #f4f9e8 , #ddebf5);
    /* linear-gradient(to right, #f3ffd9 , #aac3d5) */
    /* border-bottom: solid #74BA98; */
    border-bottom: solid #b1d0e5;
   
}

.bckg1 {
    background-image: linear-gradient(to right, #ffffff , #eef5fa);
}

.bckg2 {
    background-image: linear-gradient(to right, #f6f9ee ,#ffffff);
}

.btnLng {
    margin-left: 10px;
    margin-right: 10px;

}


.menuButton {
    border: solid 1px;
    padding: 0px;

}

.mlink {
    align-items: center;
    padding-left: 10vw;
    text-transform: uppercase;
    justify-content: center;

}

a.nav-link {
    color: #131216;
    font-size: calc(0.4vw + 12px);
    /* margin-right: 1.6em; */
}

.dropdown-item {
    font-size: calc(0.4vw + 12px);
    ;
}

.menuButton .nav-link {
    padding: 0px;
    margin-right: 0;
}

.mbg {
    font-family: 'Times New Roman', Times, serif;
    color: #131216;
}

.mbg .navbar-toggler.collapsed {
    margin-right: 10px;
}

.ptop {
    padding-top: calc(1vw + 80px);
}

.papirus {
    margin-top: calc(-25vw - 20px);
    font-family: 'Papirus', sans-serif;
    /* text-shadow: 2px 1px 0px #ffffff; */
    font-size: calc(2.2vw + 10px);
    /* font-weight: 400; */
    opacity: 0.95;
    /* text-align: justify; */
    padding-right: calc(2vw);
    padding-left: calc(2vw);
    text-align: end;
}

.opc {
    opacity: 0.85;
}

.mtop {
    justify-content: center;
    color: #131216;
    padding-top: calc(3vw);
    /* font-size: calc(1.8vw); */
    padding-left: calc(6vw);
    padding-right: calc(6vw);
}


.mh3 {
    line-height: 1.5;
    font-size: calc(0.5vw + 14px);
}

.mh3p {
    line-height: 1.5;
    font-size: calc(0.5vw + 14px);
    padding-top: calc(1vw + 12px);
    text-align: justify;
    display: inline-block;

}

.mh3p1 {
    line-height: 1.5;
    font-size: calc(0.5vw + 14px);
    font-weight: bold;
    /* padding-top: calc(1vw + 12px);; */
    text-align: center;
    /* padding-bottom: calc(3.5vw); */

}

/* .mh3j {
    line-height: 1.5;
    font-size: calc(1vw + 12px);
    text-align: justify;
} */

.mh1 {
    line-height: 1.5;
    font-size: calc(0.5vw + 18px);
    font-style: italic;
}

.nowrap {
    display: inline-block;
}

.h1M {
    color: green;
    font-size: calc(0.5vw + 16px);
    margin-left: calc(0.6vw + 5px);
    font-style: italic;
    display: inline-block;
}

.h1M1 {
    font-size: calc(1.2vw + 14px);
    margin-left: calc(0.6vw + 5px);
    font-style: bold;
    font-weight: 600;
}

.h3M {
    color: green;
    font-size: calc(0.5vw + 14px);
    padding-top: calc(1vw + 5px);
    ;
    margin-right: calc(5vw + 5px);
    font-style: italic;
    line-height: 1.5;
}

.va-center {
    align-items: center;
}

/* .pr {
    padding-right: calc(8vw + 5px);;
} */