/* @font-face {
  font-family: 'Times New Roman';
  src: url('./times new roman.ttf');
} */
body {
  /* background-color: rgba(255, 255, 255, 0.7); */
  /* background-image: url('./images/background3.webp'); */
  
 
  margin: 0;
  /* font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Times New Roman', Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
